import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'

import locale from 'element-ui/lib/locale/lang/zh-CN'
// import './permission'
import router from './router'
import store from './store'


import '@/styles/index.scss'
import '@/icons'
// import Pagination from "@/components/Pagination";

 

Vue.use(ElementUI, { locale })
Vue.config.productionTip = false
// Vue.component('Pagination', Pagination)
// 过滤器
import * as custom from './utils/util'
import {getAuthRoles} from "@/utils/auth";
// const VueScrollTo = require('vue-scrollto');



// 路由拦截器
router.beforeEach((to, from, next) => {
	console.log(to.path);
	let auth_roles = getAuthRoles();
	console.log(to.meta.title);
	
	
	
	if(to.meta.title!=undefined&&to.path!="/"&&to.path!="/login"&&auth_roles!=""){
		 let have_auth = auth_roles.includes(to.path);
		 console.log(have_auth);
		 // Vue.prototype.$message = to.name+'没有访问权限';
		 if(!have_auth){
			 alert(to.meta.title+'没有访问权限');
			 return;
		 }
		
	}
    if (to.matched.length != 0) {
        if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
            if (Boolean(localStorage.getItem("userInfo"))) { // 通过vuex state获取当前的user是否存在
                next();
            } else {
                next({
                    path: '/login',
                    // query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
                })
            }
        } else {
            if (Boolean(localStorage.getItem("userInfo"))) { // 判断是否登录
                if (to.path != "/login") { //判断是否要跳到登录界面
                    next();
                } else {
                    /**
                     * 防刷新，如果登录，修改路由跳转到登录页面，修改路由为登录后的首页
                     */
                    next({
                        path: '/'
                    })
                }
            } else {
                next();
            }
        }
    } else {
        next({
            path: '/login',
            query: { redirect: to.fullPath+"?v=1" } // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
    }
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
